const endpointConfig = require('./endpoint-config');

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
exports.shouldUpdateScroll = ({prevRouterProps, pathname}) => {
    const comingFromInternalPage = prevRouterProps.location.origin === endpointConfig.getSiteUrl();
    const body = document.querySelector('body');

    if (!comingFromInternalPage) {
        body.scrollTo(0, 0);
    } else {
        const mainContent = document.querySelector('#js-main-content');
        const siteNavbar = document.querySelector('#js-site-navbar');
        mainContent.scrollIntoView();
        body.scrollTop -= siteNavbar.offsetHeight;
    }
}
